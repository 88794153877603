.playlist-info-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
  
.playlist-thumbnail {
    width: 180px;
    height: 180px;
    border-radius: 20%;
    overflow: hidden;
}
  
.playlist-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.playlist-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.playlist-details-container:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}